@import "/public/assets/css/variables";

.List_Item {
  list-style: none;
  padding: 0 20px;
  position: relative;

  .Link {
    text-decoration: none;
    font-size: 1.8rem;
    font-weight: 600;
    color: #faeed1;
    // color: #b2a59b;
    transition: 0.3s ease-in-out;
  }

  .Link:hover {
    color: #607274;
  }

  .active {
    color: white;
    padding-bottom: 0.5rem;
    border-bottom: white solid 2px;
    // color: #607274;
  }
}

.List_Item2 {
  list-style: none;
  padding: 0 20px;
  position: relative;

  .Link {
    text-decoration: none;
    font-size: 1.8rem;
    font-weight: 600;
    color: #b2a59b;

    transition: 0.3s ease-in-out;
  }

  .Link:hover {
    color: #607274;
  }

  .active {
    color: #607274;
    padding-bottom: 0.5rem;
    border-bottom: #607274 solid 2px;
  }
}

@media (max-width: $break-point-tab-sm) {
  .List_Item {
    .Link {
      font-size: 2rem;
      font-weight: 600;
      color: grey;
    }

    .Link:hover {
      color: black;
    }

    .active {
      color: black;
      border-bottom: black solid 2px;
      // color: #607274;
    }
  }

  .List_Item2 {
    .Link {
      font-size: 2rem;
    }
  }
}

@media (min-width: $break-point-tab-sm) and (max-width: 806px) {
  .List_Item {
    padding: 0px 4px;

    .Link {
      color: black;
      font-size: 2rem;
    }
    .active {
      color: black;
      border-bottom: black solid 2px;
      // color: #607274;
    }
  }
}

@media (min-width: 807px) and (max-width: $break-point-laptop) {
  .List_Item {
    padding: 0px 5px;
  }
}
