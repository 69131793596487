@import "/public/assets/css/variables";

@keyframes fade-down {
  0% {
    opacity: 0;
    transform: translateY(-30px) scale(0.9);
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

.Navbar {
  display: flex;
  background-color: transparent;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 10rem;
  position: absolute;
  top: 0;
  height: 60px;
  width: 100%;
  animation: fade-down 0.7s 0.4s backwards;
}

.Navbar2 {
  display: flex;
  // background-color: #faeed1;
  background-color: transparent;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 10rem;
  position: absolute;
  top: 0;
  height: 60px;
  width: 100%;

  .Navbar_Logo {
    font-size: 3rem;
    font-weight: 700;
    text-decoration: none;
    color: black;
  }
}

.Navbar_Logo {
  font-size: 2.8rem;
  font-weight: 700;
  text-decoration: none;
  color: white;
}

.Navbar_Items {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Hamburger_Menu {
  display: none;
  position: absolute;
  top: 2rem;
  right: 2rem;
  flex-direction: column;
  justify-content: space-between;
  width: 2.5rem;
  height: 2rem;
}

.Hamburger_Menu span {
  height: 0.4rem;
  width: 100%;
  background-color: black;
  border-radius: 0.2rem;
}

@media (max-width: $break-point-mobile) {
  .Hamburger_Menu {
    display: flex;
  }

  .Hamburger_Menu span {
    height: 0.3rem;
    width: 100%;
    background-color: $neutral-700;
    border-radius: 0.2rem;
  }

  .Navbar2 {
    .Hamburger_Menu span {
      background-color: $neutral-600;
    }
  }

  .Navbar,
  .Navbar2 {
    padding: 1.5rem;
    flex-direction: column;
    align-items: start;
  }

  .Navbar ul,
  .Navbar2 ul {
    display: none;
    flex-direction: column;
    width: 100%;
    margin-bottom: 0.25rem;
  }

  .Navbar ul li,
  .Navbar2 ul li {
    width: 100%;
    text-align: center;
  }

  .Navbar ul.open,
  .Navbar2 ul.open {
    display: flex;
    background-color: $neutral-100;
    border-radius: 1rem;
    z-index: 1;

    li {
      padding: 1rem 0;
    }
  }

  .Navbar ul li,
  .Navbar2 ul li {
    width: 100%;
    text-align: center;
  }

  .Navbar_Items {
    padding: 0;
  }
}

@media (min-width: $break-point-mobile) and (max-width: $break-point-tab-lg) {
  .Hamburger_Menu {
    display: flex;
    top: 3rem;
    right: 2rem;
  }

  .Hamburger_Menu span {
    height: 0.3rem;
    width: 100%;
    background-color: $neutral-700;
    border-radius: 0.2rem;
  }

  .Navbar2 {
    .Hamburger_Menu span {
      background-color: $neutral-600;
    }
  }

  .Navbar {
    padding: 3rem 10rem;
    flex-direction: column;
    align-items: start;
  }
  .Navbar2 {
    padding: 2rem;
    flex-direction: column;
    align-items: start;
  }

  .Navbar ul,
  .Navbar2 ul {
    display: none;
    flex-direction: column;
    width: 100%;
    margin-bottom: 0.25rem;
  }

  .Navbar ul li,
  .Navbar2 ul li {
    width: 100%;
    text-align: center;
  }

  .Navbar ul.open,
  .Navbar2 ul.open {
    display: flex;
    background-color: $neutral-100;
    border-radius: 1rem;
    z-index: 1;

    li {
      padding: 1rem 0;
    }
  }

  .Navbar ul li,
  .Navbar2 ul li {
    width: 100%;
    text-align: center;
  }

  .Navbar_Items {
    padding: 0;
  }
}
