@import "/public/assets/css/variables";

.About_Us_Page {
  background-image: url("./../../assets/images/white-brick-wall.png");
  padding: 15rem 20rem 0rem;

  .Upper_Div {
    display: flex;

    .UD_Left {
      flex: 6;
      margin-right: 4rem;

      h1 {
        font-size: 3rem;
        margin-bottom: 4rem;
      }

      p {
        font-size: 1.8rem;
        color: #607274;
        margin-bottom: 2rem;
        text-align: justify;
      }

      button {
        background-color: #607274;
        color: white;
        padding: 1.5rem 3rem;
        font-size: 2rem;
        border: none;
        border-radius: 2px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

        &:hover {
          background-color: #faeed1;
          color: black;
        }
      }
    }

    .UD_Right {
      flex: 5;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  hr {
    width: 100%;
    margin: 10rem 0 7rem;
    border: 1px solid #607274;
  }

  .Lower_Div {
    display: flex;
    flex-direction: column;
    align-items: center;

    .Heading3 {
      font-size: 4rem;
    }

    p {
      font-size: 1.8rem;
      color: #607274;
      margin: 5rem 0;
      text-align: center;
    }

    .LD_Inner_Div {
      display: flex;
      gap: 5rem;

      img {
        width: 50rem;
        height: 50rem;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
      }

      div {
        display: flex;
        flex-direction: column;
        align-items: left;

        .H3 {
          font-size: 3rem;
          margin: 0px;
        }

        p {
          margin: 2rem 0;
          text-align: left;
        }
      }
    }

    .LD_Inner_Div2 {
      margin: 10rem 0;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: 1fr 1fr;
      gap: 5rem;
    }
  }
}

@media (max-width: $break-point-mobile) {
  .About_Us_Page {
    padding: 6rem 2rem;

    .Upper_Div {
      display: flex;
      flex-direction: column-reverse;

      .UD_Left {
        flex: 1;
        margin-right: 0rem;
        display: flex;
        flex-direction: column;
        align-items: center;

        h1 {
          font-size: 2.7rem;
          font-weight: 700;
          margin: 2rem 0 1rem;
          text-align: center;
        }

        p {
          text-align: justify;
        }

        button {
          align-self: center;
          padding: 1.5rem 3rem;
          font-size: 2rem;
          border: none;
          border-radius: 2px;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

          &:hover {
            background-color: #faeed1;
            color: black;
          }
        }
      }

      .UD_Right {
        flex: 1;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    hr {
      margin: 3rem 0 1rem;
    }

    .Lower_Div {
      .Heading3 {
        font-size: 3rem;
      }

      p {
        margin: 1rem 0;
        text-align: justify;
      }

      .LD_Inner_Div {
        flex-direction: column;
        align-items: center;
        gap: 2rem;
        img {
          margin-top: 2rem;
          width: 25rem;
          height: 25rem;
          border-radius: 50%;
        }

        div {
          align-items: center;

          p {
            text-align: justify;
          }
        }
      }

      .LD_Inner_Div2 {
        margin: 2rem;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        gap: 2rem;
      }
    }
  }
}

@media (min-width: $break-point-mobile) and (max-width: $break-point-tab-lg) {
  .About_Us_Page {
    padding: 7rem 2rem;

    .Upper_Div {
      .UD_Left {
        margin-right: 1rem;

        h1 {
          margin-bottom: 1rem;
        }

        p {
          text-align: justify;
        }

        button {
          border-radius: 4px;
        }
      }
    }

    hr {
      margin: 3rem 0;
    }
    .Lower_Div {
      .Heading3 {
        font-size: 4rem;
      }

      p {
        margin: 1rem 0;
        text-align: center;
      }

      .LD_Inner_Div {
        flex-direction: column;
        align-items: center;
        gap: 2rem;
        img {
          margin-top: 2rem;
          width: 25rem;
          height: 25rem;
          border-radius: 50%;
        }

        div {
          align-items: center;

          p {
            text-align: justify;
          }
        }
      }

      .LD_Inner_Div2 {
        margin: 2rem;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr;
        gap: 2rem;
      }
    }
  }
}
