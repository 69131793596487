@import "/public/assets/css/variables";

.Card2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 10rem;
    height: 10rem;
  }

  h3 {
    font-size: 2.5rem;
    font-weight: 600;
    color: #000000;
    margin-top: 2rem;
    text-align: center;
  }
}

.Card2:hover {
  cursor: pointer;
  transform: scale(1.2);
  transition: all 0.5s ease-in-out;

  h3 {
    color: #607274;
  }
}

@media (max-width: $break-point-mobile) {
  .Card2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h3 {
      font-size: 2.5rem;
      margin-top: 1rem;
    }
  }
}
