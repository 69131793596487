@import "/public/assets/css/variables";

.Projects {
  width: 100%;
  background-color: #faeed1;
  padding: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("./../../../assets/images/white-brick-wall.png");

  h2 {
    font-size: 4rem;
    color: black;
    opacity: 0;
    transform: translateY(-100px);
    transition: all 0.5s ease-in-out;

    &.heading-animation {
      opacity: 1;
      transform: translateY(0);
    }
  }

  hr {
    width: 10%;
    margin-top: 1rem;
    border: 0.1rem solid #607274;
    margin-bottom: 4rem;
    opacity: 0;
    transform: translateY(-100px);

    &.hr-animation {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .Inner_Div3 {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    opacity: 0;
    transition: all 1s ease-in-out;

    &.div-animation {
      opacity: 1;
    }

    img:hover {
      cursor: pointer;
      transform: scale(1.1);
      transition: 0.5s;
      border: 5px solid #b2a59b;
    }

    .Image_Gallery_Row1 {
      display: flex;
      gap: 1rem;

      img {
        width: 30rem;
        height: 30rem;
        margin-bottom: 1rem;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
      }
    }

    .Image_Gallery_Row2 {
      display: flex;
      gap: 1rem;

      img {
        width: 45.5rem;
        height: 30rem;
        margin-bottom: 1rem;
      }
    }

    .Hover_Circle {
      position: absolute;
      left: 0;
      top: 0;
      background-color: #607274;
      border-radius: 50%;
      height: 150px;
      width: 150px;
      display: none;
      justify-content: center;
      align-items: center;
      color: white;
      opacity: 1;
      pointer-events: none;
      mix-blend-mode: difference;
      font-size: 2rem;
      color: white;
    }
  }
}

@media (max-width: $break-point-mobile) {
  .Projects {
    padding: 0rem;
    padding-bottom: 2rem;
    h2 {
      font-size: 3.5rem;
      margin-top: 2rem;
    }

    hr {
      width: 20%;
      margin-bottom: 2.5rem;
    }

    .Inner_Div3 {
      width: 100%;

      .Image_Gallery_Row1 {
        flex-direction: column;
      }

      .Image_Gallery_Row2 {
        flex-direction: column;

        img {
          width: 30rem;
        }
      }

      .Hover_Circle {
        position: absolute;
        left: 0;
        top: 0;
        background-color: #607274;
        border-radius: 50%;
        height: 150px;
        width: 150px;
        display: none;
        justify-content: center;
        align-items: center;
        color: white;
        opacity: 1;
        pointer-events: none;
        mix-blend-mode: difference;
        font-size: 2rem;
        color: white;
      }
    }
  }
}

@media (min-width: $break-point-mobile) and (max-width: $break-point-tab-lg) {
  .Projects {
    padding: 0rem;
    padding-bottom: 2rem;

    h2 {
      font-size: 3.5rem;
      margin-top: 2rem;
    }
    .Inner_Div3 {
      width: 100%;

      .Image_Gallery_Row1 {
        img {
          width: 25rem;
        }
      }

      .Image_Gallery_Row2 {
        img {
          width: 30rem;
        }
      }
    }
  }
}
