@import "/public/assets/css/variables";

.About_Section {
  padding: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $neutral-700;
  background-image: url("./../../../assets/images/white-brick-wall.png");
  opacity: 0;
  // transform: translateY(100px);
  transition: all 1s ease-in-out;

  &.about-animation {
    opacity: 1;
    // transform: translateY(0);
  }

  img {
    width: 20rem;
    height: 20rem;
    border-radius: 50%;
    margin-bottom: 2rem;
    border: 5px solid #607274;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }

  h3 {
    font-size: 3rem;
  }

  h4 {
    font-size: 2.5rem;
    color: #607274;
    margin-bottom: 1rem;
  }

  hr {
    width: 10%;
    margin-top: 1rem;
    border: 0.1rem solid #607274;
    margin-bottom: 4rem;
  }

  p {
    padding: 0 25rem;
    font-size: 1.75rem;
    text-align: center;
  }
}

@media (max-width: $break-point-tab-lg) {
  .About_Section {
    padding: 0rem;

    img {
      width: 15rem;
      height: 15rem;
      margin-top: 2rem;
    }

    hr {
      width: 20%;
      margin-top: 0rem;
      margin-bottom: 0rem;
    }

    p {
      padding: 2rem;
      font-size: 2rem;
      color: $neutral-300;
      padding-bottom: 2rem;
    }
  }
}
