.Card3 {
  border: 1px solid #ded0b6;
  padding: 4rem;
  display: flex;
  flex-direction: column;
  align-items: left;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

  img {
    width: 10rem;
    height: 10rem;
  }

  h2 {
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.8rem;
    color: #607274;
    text-align: left;
  }
}
