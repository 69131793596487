@import "/public/assets/css/variables";

.Contact_Us_Page {
  background-image: url("./../../assets/images/white-brick-wall.png");
  padding: 15rem 20rem 10rem;

  .Contact_Inner_Div {
    display: grid;
    grid-template-columns: 3fr 8fr;
    grid-template-rows: 65vh;
    gap: 4rem;

    .Left {
      display: flex;
      flex-direction: column;
      gap: 2rem;

      div {
        background-color: #faeed1;
        padding: 2rem;
        display: grid;
        grid-template-columns: 2fr 8fr;
        grid-template-rows: 1fr 1fr;
        max-height: 18rem;

        img {
          width: 3rem;
          height: 3rem;
          grid-row: span 2;
        }

        h3 {
          font-size: 2.2rem;
          margin-bottom: 1rem;
        }

        p {
          color: #607274;
          font-size: 1.5rem;
        }
      }
    }

    .Right {
      display: flex;
      flex-direction: column;
      align-items: left;

      h2 {
        font-size: 2.8rem;
        color: black;
      }

      hr {
        width: 20%;
        margin-top: 1rem;
        margin-bottom: 2rem;
        border: 1px solid #607274;
      }

      p {
        font-size: 1.8rem;
        padding-right: 5rem;
        color: #607274;
      }

      .Form {
        position: relative;
        display: grid;
        width: 100%;
        padding: 3rem 0;
        padding-right: 6rem;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr 1fr;
        //for other items column size is full,

        gap: 2rem;

        input {
          width: 100%;
          height: 4rem;
          border: 0.1rem solid #607274;
          padding: 0 1rem;
          font-size: 1.5rem;
        }

        input:focus,
        textarea:focus {
          border: 2px solid black; /* Change the color and width as needed */
          outline: none; /* Remove the default browser outline */
        }

        textarea {
          grid-column: span 2;
          height: 10rem;
          max-height: 10rem;
          padding: 1rem;
          font-size: 1.8rem;
          margin-bottom: 1rem;
        }

        button {
          grid-column: span 2;
          width: 15rem;
          font-size: 2.2rem;
          padding: 1.2rem;
          background-color: #faeed1;
          border: 2px solid black;
          color: black;
        }

        button:hover {
          background-color: #607274;
          color: #faeed1;
          border: 2px solid #faeed1;
        }

        input:focus,
        textarea:focus {
          border: 2px solid black; /* Change the color and width as needed */
          outline: none; /* Remove the default browser outline */
        }
      }
    }
  }

  .Iframe {
    width: 100%;
    height: 50rem;
    border: 0;
  }
}

@media (max-width: $break-point-mobile) {
  .Contact_Us_Page {
    padding: 6.5rem 0 0;

    .Contact_Inner_Div {
      display: flex;
      flex-direction: column-reverse;
      gap: 1rem;

      .Left {
        padding: 0 3rem;

        div {
          padding: 1rem;
          max-height: 14rem;

          h3 {
            font-size: 2.5rem;
          }

          p {
            font-size: 1.75rem;
          }
        }
      }

      .Right {
        align-items: center;
        hr {
          margin-bottom: 1rem;
        }

        p {
          text-align: left;
          padding: 0 2.5rem;
        }

        .Form {
          padding: 3rem;
        }
      }
    }

    .Iframe {
      height: 30rem;
      margin: 3rem 0;
    }
  }
}

@media (min-width: $break-point-mobile) and (max-width: $break-point-tab-lg) {
  .Contact_Us_Page {
    padding: 7.5rem 2rem 2rem;

    .Contact_Inner_Div {
      gap: 3rem;
      grid-template-rows: 38vh;

      .Right {
        p {
          padding-right: 2rem;
        }

        .Form {
          padding: 1rem 0;
          padding-right: 2rem;
        }
      }
    }

    .Iframe {
      width: 100%;
      height: 50rem;
      border: 0;
    }
  }
}
