@import "/public/assets/css/variables";

.Careers {
  background-image: url("./../../assets/images/white-brick-wall.png");
  padding: 15rem 20rem 10rem;
  display: flex;

  .Careers_Left {
    display: flex;
    flex-direction: column;
    align-items: left;
    flex: 1.1;

    h1 {
      font-size: 3rem;
      color: #607274;
      margin-bottom: 1rem;
    }

    h2 {
      font-size: 3.5rem;
      color: black;
    }

    hr {
      width: 25%;
      margin: 1rem 0;
      border: 1px solid #607274;
    }

    p {
      margin: 1rem 0;
      font-size: 1.8rem;
      color: #607274;
      padding-right: 2rem;
    }

    button {
      background-color: #607274;
      width: 40%;
      color: white;
      padding: 1.5rem 3rem;
      font-size: 2rem;
      border: none;
      border-radius: 2px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      margin-top: 1rem;

      &:hover {
        background-color: #faeed1;
        color: black;
      }
    }
  }

  .Careers_Right {
    flex: 1.3;
    display: flex;
    justify-content: right;

    img {
      width: 60rem;
      height: 60rem;
    }
  }
}

@media (max-width: $break-point-mobile) {
  .Careers {
    padding: 7rem 1.5rem 3rem;
    flex-direction: column-reverse;
    align-items: center;

    .Careers_Left {
      display: flex;
      flex-direction: column;
      align-items: center;

      h1 {
        font-size: 4rem;
        color: #607274;
        margin-top: 1rem;
      }

      h2 {
        text-align: center;
      }

      p {
        margin: 0;
        text-align: justify;
      }

      button {
        width: 50%;
        font-size: 2.5rem;
        border-radius: 1rem;
        margin-top: 2rem;
      }
    }

    .Careers_Right {
      margin-top: 1rem;
      img {
        width: 40rem;
        height: 40rem;
        border-radius: 1rem;
        align-self: center;
      }
    }
  }
}

@media (min-width: $break-point-mobile) and (max-width: $break-point-tab-lg) {
  .Careers {
    padding: 7rem 1.5rem 3rem;
    flex-direction: column-reverse;
    align-items: center;

    .Careers_Left {
      display: flex;
      flex-direction: column;
      align-items: center;

      h1 {
        font-size: 4rem;
        color: #607274;
        margin-top: 1rem;
      }

      h2 {
        text-align: center;
      }

      p {
        margin: 0;
        text-align: justify;
      }

      button {
        width: 50%;
        font-size: 2.5rem;
        border-radius: 1rem;
        margin-top: 2rem;
      }
    }

    .Careers_Right {
      margin-top: 1rem;
      img {
        width: 40rem;
        height: 40rem;
        border-radius: 1rem;
        align-self: center;
      }
    }
  }
}
