.ListItem2 {
  display: flex;
  align-items: center;

  img {
    width: 3.5rem;
    height: 3.5rem;
    margin-right: 1rem;
  }

  h4 {
    font-size: 2.2rem;
    color: #607274;
    text-align: left;
  }
}
