@import "/public/assets/css/variables";

.Philosophy {
  // height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10rem;
  background-color: $neutral-700;
  background-image: url("./../../../assets/images/white-brick-wall.png");

  h2 {
    font-size: 4rem;
    color: black;
    opacity: 0;
    transform: translateY(-100px);
    transition: all 0.5s ease-in-out;

    &.heading-animation {
      opacity: 1;
      transform: translateY(0);
    }
  }

  hr {
    width: 20%;
    margin-top: 1rem;
    margin-bottom: 5rem;
    border: 0.1rem solid #607274;
    opacity: 0;
    transform: translateY(-100px);
    transition: all 0.5s ease-in-out;

    &.hr-animation {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .Inner_div {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 3rem;
    width: 80%;
    opacity: 0;
    transform: translateY(100px);
    transition: all 0.5s ease-in-out;

    &.div-animation {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

@media (max-width: $break-point-tab-lg) {
  .Philosophy {
    height: fit-content;
    padding: 0;
    padding-bottom: 3rem;

    h2 {
      font-size: 3.5rem;
      margin-top: 2rem;
    }

    hr {
      margin-bottom: 2rem;
    }

    .Inner_div {
      grid-template-columns: 1fr;
      grid-gap: 2rem;
    }
  }
}
