@import "/public/assets/css/variables";

.Card {
  background-color: #ded0b6;
  border-radius: 0rem 1rem 1rem 0rem;
  border-left: 1rem solid #607274;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.Card h1 {
  margin: 0px;
  font-size: 3rem;
  color: black;
}

.Card p {
  font-size: 2.5rem;
  color: #607274;
}

@media (max-width: $break-point-mobile) {
  .Card h1 {
    font-size: 3rem;
  }

  .Card p {
    text-align: center;
  }
}
