@import "/public/assets/css/variables";

.What_We_Do2 {
  height: 85vh;
  padding: 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #faeed1;
  background-image: url("./../../../assets/images/white-brick-wall.png");
  h2 {
    color: black;
    font-size: 4rem;
    opacity: 0;
    transform: translateY(-100px);
    transition: all 0.5s ease-in-out;

    &.heading-animation {
      opacity: 1;
      transform: translateY(0);
    }
  }

  hr {
    width: 10%;
    margin-top: 2rem;
    border: 0.1rem solid #607274;
    opacity: 0;
    transform: translateY(-100px);
    transition: all 0.5s ease-in-out;

    &.hr-animation {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

.What_We_Do2 .Inner_Div {
  margin-top: 7rem;
  width: 80%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 15rem 15rem;
  gap: 5rem;
  opacity: 0;
  transform: translateY(100px);
  transition: all 0.5s ease-in-out;

  &.div-animation {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: $break-point-mobile) {
  .What_We_Do2 {
    height: 100%;
    padding: 0rem;
    margin: 0;
    height: 75rem;

    h2 {
      margin-top: 2rem;
      font-size: 3rem;
      padding: 0rem 2rem;
      text-align: center;
    }

    hr {
      width: 20%;
    }
  }

  .What_We_Do2 .Inner_Div {
    margin-top: 3.5rem;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(3, 20rem);
    gap: 0rem;
  }
}

@media (min-width: $break-point-mobile) and (max-width: $break-point-tab-lg) {
  .What_We_Do2 {
    padding: 0rem;
    margin: 0;
    height: 60rem;

    h2 {
      margin-top: 2rem;
      font-size: 3rem;
      padding: 0rem 2rem;
      text-align: center;
    }

    hr {
      width: 20%;
    }
  }

  .What_We_Do2 .Inner_Div {
    margin-top: 3.5rem;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: repeat(1, 20rem);
    gap: 0rem;
  }
}
