@import "/public/assets/css/variables";

.Contact_Us {
  padding: 10rem;
  background-image: url("./../../../assets/images/building2.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;

  h3 {
    font-size: 4rem;
    margin-bottom: 1rem;
    color: #faeed1;
  }

  hr {
    width: 10%;
    margin-top: 1rem;
    border: 0.1rem solid #faeed1;
    margin-bottom: 4rem;
  }

  .Form {
    position: relative;
    display: grid;
    width: 100%;
    padding: 0 30rem;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr 1fr;
    //for other items column size is full,

    gap: 2rem;

    input {
      width: 100%;
      height: 4rem;
      border: 0.1rem solid #607274;
      padding: 0 1rem;
      font-size: 1.5rem;
    }

    input:focus,
    textarea:focus {
      border: 2px solid black; /* Change the color and width as needed */
      outline: none; /* Remove the default browser outline */
    }

    textarea {
      grid-column: span 2;
      height: 10rem;
      max-height: 10rem;
      padding: 1rem;
      font-size: 1.8rem;
    }

    button {
      grid-column: span 2;
      justify-self: center;
      width: 15rem;
      font-size: 2rem;
      padding: 1rem;
      background-color: #faeed1;
      border: none;
      border-radius: 2.5rem;
    }

    button:hover {
      background-color: #607274;
      color: #faeed1;
      border: 2px solid #faeed1;
    }

    input:focus,
    textarea:focus {
      border: 2px solid black; /* Change the color and width as needed */
      outline: none; /* Remove the default browser outline */
    }
  }
}

@media (max-width: $break-point-tab-lg) {
  .Contact_Us {
    padding: 0rem;
    padding-bottom: 3rem;

    h3 {
      font-size: 3.5rem;
      margin-top: 2rem;
    }

    hr {
      width: 20%;
      margin-bottom: 2.5rem;
    }

    .Form {
      padding: 0 5rem;

      button {
        color: $neutral-600;
      }
    }
  }
}
