@import "/public/assets/css/variables";

.Card_About_Us {
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 20rem;
    height: 20rem;
    border-radius: 50%;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }

  h3 {
    margin-top: 2rem;
    font-size: 2.5rem;
  }

  h4 {
    font-size: 2rem;
    text-align: center;
    color: #929b9c;
  }

  h5 {
    font-size: 1.8rem;
    text-align: center;
    color: #959b9b;
  }
}

@media (max-width: $break-point-mobile) {
  .Card_About_Us {
    img {
      width: 15rem;
      height: 15rem;
    }

    h3 {
      margin-top: 1rem;
      font-size: 2rem;
      text-align: center;
    }

    h4 {
      font-size: 1.75rem;
      text-align: center;
      color: #607274;
    }
  }
}
