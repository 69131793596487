/* primary color */
$primary-purple: #843df5;
$secondary-blue: #479eef;
$primary-green: #007656;
$primary-bg: #0d0d0d;
$primary-red: #eb5939;
$primary-yellow: #fabf29;
$primary-pink: #ffccde;
$footer-bg: #1b1d20;

/* tint */
$tint-orange: #c5c0bc;

/* neutral */
$neutral-000: #e5e5e5;
$neutral-100: #dfdfe0;
$neutral-200: #b9b9b9;
$neutral-300: #8b8b8b;
$neutral-400: #3f3f45;
$neutral-500: #2e2e2e;
$neutral-600: #0d0d0d;
$neutral-700: #ffffff;

/* box-shadow */
$box-shadow-light: 0.2rem 0.2rem 1rem rgba(0, 0, 0, 0.1);

/* break points */
$break-point-desktop: 1400px;
$break-point-desktop-sm: 1200px;
$break-point-laptop: 1024px;
// $break-point-tab-lg: 992px;
$break-point-tab-lg: 1024px;
$break-point-tab: 850px;
$break-point-tab-sm: 768px;
$break-point-mobile-lg: 650px;
$break-point-mobile: 768px;
$break-point-mobile-sm: 430px;

/*radius */
$br-0: 6px;
$br-1: 10px;
$br-2: 20px;
$br-3: 30px;
$br-4: 40px;

/* z-index */
$z-cursor: 400;
$z-page-loader: 350;
$z-sidebar: 300;
$z-subnav: 250;
$z-projects: 200;
$z-navbar: 150;

/* responsive value */
$index: calc(1vw + 1vh);

/* ease functions */
$e-bounce: cubic-bezier(0.175, 0.885, 0.32, 1.2);
$e-smooth: cubic-bezier(0.2, 0.5, 0, 1);
$e-ease: ease;

/* animation delay */
$d-initial-page-load: 5s;
$d-pageLoader: 2s;
$d-sidebar-load: 0.4s;
$d-logo: 0.5s;
$project-open-time: 1s;

/* height */
$navbar-height: 60px;
$navbar-height-sm: 60px;

/* padding */
$pd-container: 100px;
$pd-container-tab: 30px;
$pd-container-sm: 16px;
