@import "/public/assets/css/variables";

.Projects_Page {
  background-image: url("./../../assets/images/white-brick-wall.png");
  padding: 15rem 10rem 10rem;

  img {
    max-width: 100%;
    height: auto;
    object-fit: cover;
    vertical-align: middle;
    display: inline-block;
  }

  .Gallery_Container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 10px;
    grid-auto-rows: 200px;
    grid-auto-flow: dense;

    .flip-card {
      background-color: transparent;
      width: 100%;
      height: 100%;
      border: 1px solid #f1f1f1;
      perspective: 1000px; /* Remove this if you don't want the 3D effect */
    }

    /* This container is needed to position the front and back side */
    .flip-card-inner {
      position: relative;
      width: 100%;
      height: 100%;
      text-align: center;
      transition: transform 0.8s;
      transform-style: preserve-3d;
    }

    /* Do an horizontal flip when you move the mouse over the flip box container */
    .flip-card:hover .flip-card-inner {
      transform: rotateY(180deg);
    }

    /* Position the front and back side */
    .flip-card-front,
    .flip-card-back {
      position: absolute;
      width: 100%;
      height: 100%;
      -webkit-backface-visibility: hidden; /* Safari */
      backface-visibility: hidden;
    }

    /* Style the front side (fallback if image is missing) */
    .flip-card-front {
    }

    /* Style the back side */
    .flip-card-back {
      background-color: $neutral-000;
      color: $neutral-600;
      transform: rotateY(180deg);
    }

    div {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 5px;
      }
    }

    .wide {
      grid-column: span 2;
    }

    .tall {
      grid-row: span 2;
    }

    .tall3 {
      grid-row: span 3;
    }

    .tall4 {
      grid-row: span 4;
    }

    .big {
      grid-column: span 2;
      grid-row: span 2;
    }

    .big3 {
      grid-column: span 3;
      grid-row: span 3;
    }

    .wide2tall3 {
      grid-column: span 2;
      grid-row: span 3;
    }

    .wide5tall2 {
      grid-column: span 5;
      grid-row: span 2;
    }
  }
}

@media (max-width: $break-point-mobile) {
  .Projects_Page {
    padding: 7rem 1.5rem;

    .Gallery_Container {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-gap: 10px;
      grid-auto-rows: 100px;

      .wide {
        grid-column: span 1;
      }

      .tall {
        grid-row: span 1;
      }

      .tall3 {
        grid-row: span 3;
      }

      .tall4 {
        grid-row: span 3;
      }

      .big {
        grid-column: span 2;
        grid-row: span 2;
      }

      .big3 {
        grid-column: span 2;
        grid-row: span 3;
      }

      .wide2tall3 {
        grid-column: span 2;
        grid-row: span 3;
      }

      .wide5tall2 {
        grid-column: span 3;
        grid-row: span 3;
      }
    }
  }
}
